@mixin form-textbox-styles {
    border: 1px solid $color-grey-03;
    background-color: $color-white;
}

@mixin form-textbox {
    display: block;
    width: 100%;
    outline: none;
    line-height: normal;
    padding: $form-spacing*1.25 $form-spacing*2 $form-spacing*1.12;
    -webkit-appearance: none;
    line-height: 1.4;
}

@mixin form-select-styles {
    -moz-appearance: none;
    background-image: url(../assets/icon-system/icon_form_arrow-down.svg);
    background-repeat: no-repeat;
    background-position: center right $form-spacing;
    background-size: $form-spacing*2.5;
    padding-right: $form-spacing*4.5 !important;
}


.sidebar-linklist {
    margin: 0;
    padding: 0;
}

.sidebar-linklist__item {
    border-bottom: 1px solid #d6d6d6;
    position: relative;
}

.sidebar-linklist__item__button {
    display: block;
    background: none;
    border: none;
    padding: 10px 18px 10px 1.5em;
}

.sidebar-linklist__item__button:before {
    content: ">";
    position: absolute;
    left: 0;
    color: #000;
    top: calc(10px + 0.1em);
    transition:
        left 200ms ease-in-out,
        transform 200ms ease-in-out,
        color 200ms ease-in-out;
    transform: rotate(0deg);
    transform-origin: center;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before,
.sidebar-linklist__item:hover .sidebar-linklist__item__button:before {
    left: .5em;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before {
    transform: rotate(90deg);
}

.sidebar-linklist__item__sublist {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-linklist__item__sublist__item {
    list-style: none;
}

.sidebar-linklist__item__sublist__item__link:before {
    content: "\2013\00a0";
    color: #000;
}

.sidebar-linklist__item__sublist__item__link {
    display: block;
    text-decoration: none;
    padding: .25em .25em .25em 1.5em;
    transition: background-color 200ms ease-in-out;
}

.sidebar-linklist__item__sublist__item__link:hover,
.sidebar-linklist__item__sublist__item__link:focus,
.sidebar-linklist__item__sublist__item__link:active {
    background: rgba(0,0,0,.05);
}

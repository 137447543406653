
ul.inline,
ol.inline {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-flow: row wrap;
    gap: calc(#{$spacing}/2) $spacing;
}

ul.inline > li,
ol.inline > li {
    display: inline-block;
    padding-left: 12px;
    padding-right: 12px;

    @supports (display: flex) {
        padding-left: 0;
        padding-right: 0;
    }
}



.fusion-main-menu {
    --header_sticky_nav_padding: 32px;
    --header_sticky_menu_color: #fff;
    --header_sticky_nav_font_size: 14px;
    --nav_height: 60px;
    --mobile_nav_padding: 25px;
    --menu_text_align: center;
    --menu_thumbnail_size-width: 26px;
    --menu_thumbnail_size-height: 14px;

    @include screen-1200 {
        --header_sticky_nav_padding: 45px;
    }
}

$mobile-menu-transition: 350ms ease;


/*
* frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
*/


/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
}

#frankenmenu-list a {
    text-decoration: none;
    display: block;
}


/** Desktop menu only
===================================*/
@include screen-menu-desktop {

    #frankenmenu {
        z-index: $header-index;
    }
    #frankenmenu-list {
        display: flex;
    }
    #mobile-header {
        display: none;
    }

    /* ---- All level menu items ---- */
    #frankenmenu-list .menu-item {
        position: relative;
    }
    #frankenmenu-list .sub-menu {
        display: none;
        min-width: 200px;
        position: absolute;
        box-shadow: 0 0 16px 1px rgba(0,0,0,0.4);
        z-index: 1;
        min-width: 250px;
        max-width: 350px;
        text-align: left;
        top: 0;
        left: 100%;
    }

    /* ---- First level menu ---- */
    #frankenmenu-list .menu-item-depth1 {
        text-align: var(--menu_text_align);
        flex: 1 1 auto;
    }
    #frankenmenu-list .menu-item-depth1 > a {
        display: block;
        color: $color-white;
        padding: 0.75em 1em;

        padding-left: calc(var(--header_sticky_nav_padding)/2);
        padding-right: calc(var(--header_sticky_nav_padding)/2);
        font-weight: $fw-normal;
        text-transform: lowercase;
        font-size: $font-body;
        letter-spacing: 0;
        font-style: normal;
    }

    /* Hover */
    #frankenmenu-list .menu-item-depth1 > a:hover,
    #frankenmenu-list .menu-item-depth1 > a:focus,
    #frankenmenu-list .menu-item-depth1.frankenhover > a,
    #frankenmenu-list .menu-item-depth1.frankenhold > a {
        color: $color-grey-03;
        background-color: transparent;
    }

    /* Current item */
    #frankenmenu-list .menu-item-depth1.menu-current-item > a {
        color: $color-white;
        text-decoration: underline;
    }

    /* Ancestor item */
    #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
        background-color: darken($color-secondary, 3%);
        color: $color-white;
    }

    /* ---- Submenus ---- */
    #frankenmenu-list .sub-menu a {
        font-family: Roboto;
        font-weight: 300;
        padding: 7px 20px;
        border-top: 1px solid $color-primary;
        font-size: 13px;
        color: $color-primary;
        background-color: $color-white;
        text-transform: uppercase;

        transition:
            background-color 200ms ease,
            color 200ms ease;
    }
    #frankenmenu-list .sub-menu .menu-item:first-child a {
        border-top: none;
    }
    #frankenmenu-list .sub-menu .menu-item > a:hover,
    #frankenmenu-list .sub-menu .menu-item > a:focus,
    #frankenmenu-list .sub-menu .menu-item.frankenhover > a,
    #frankenmenu-list .sub-menu .menu-item.frankenhold > a {
        background: $color-primary;
        color: $color-white;
    }

    #frankenmenu-list .frankenhold > .sub-menu {
        display: block !important;
        opacity: 1 !important;
    }

    /* ---- First level submenus ---- */
    #frankenmenu-list .sub-menu-depth1 {
        top: 100%;
        left: auto;
    }

    /* ---- Right aligned submenus ---- */
    #frankenmenu-list .sub-menu.submenu-right-align,
    #frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
        left: auto;
        right: 100%;
    }
    #frankenmenu-list .sub-menu-depth1.submenu-right-align {
        left: auto;
        right: 0;
    }


    /* ---- Sub-navigation ---- */
    #frankenmenu-list .menu-item-subnav {
        display: none;
    }
    #frankenmenu-subnav {
        float: right;
    }
    #frankenmenu-subnav-list,
    #frankenmenu-subnav-list ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    #frankenmenu-subnav-list .menu-item-depth1 {
        display: inline-block;
    }
    #frankenmenu-subnav-list .menu-item-depth1 > a {
        text-decoration: none;
        padding: 5px 5px;
        display: block;
        color: inherit;
    }
    #frankenmenu-subnav-list .menu-item-depth1 > a:hover {
        text-decoration: underline;
    }
    #frankenmenu-subnav-list .sub-menu {
        display: none;
    }

    /* ---- Moved elements ---- */
    .frankenmoved {
        display: none;
    }

    /* ---- Megamenu ---- */
    #frankenmenu-list .mega-menu {
        left: 0;
        right: 0;
        background-color: $color-white;
        text-align: left;
        top: 100%;
        position: absolute;
        border-bottom: 4px solid $color-grey-02;
        z-index: 51;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        width: 1px;
    }
    /* Remove relative positioning for mega menu */
    .frankenmenu-mega #frankenmenu-list .menu-item {
        position: static;
    }
    #frankenmenu-list .menu-item.frankenhover .mega-menu,
    #frankenmenu-list .menu-item.frankenhold .mega-menu {
        padding: 40px 0;
        width: auto;
        height: auto;
        clip: auto;
        overflow: visible;
        margin: auto;
    }
    #frankenmenu-list .menu-item.frankenhold .mega-menu {
        display: block !important;
        height: auto !important;
    }

    /* Megamenu preview */
    #frankenmenu-list .mega-menu-preview {
        float: left;
        color: $color-white;
        padding: 18px 20px;
        background-color: $color-grey-06;
        width: 22%;
        margin-right: 3%;
    }
    #frankenmenu-list .mega-menu-preview-image {
        height: 120px;
        background: url(../images/mega-menu-image-fallback.jpg) no-repeat center center;
        background-size: cover;
    }
    #frankenmenu-list .mega-menu-preview-text {
        margin-bottom: 0;
        line-height: 1.2;
    }

    /* Megamenu columns */
    #frankenmenu-list .mega-menu .mega-menu-columns {
        float: left;
        width: 75%;
    }
    #frankenmenu-list .mega-menu-column {
        width: 31.33333333%;
        float: left;
        margin-right: 3%;
    }
    #frankenmenu-list .mega-menu-column:last-child {
        margin-right: 0;
    }

    /* Megamenu submenus */
    #frankenmenu-list .mega-menu-submenu .menu-item {
        border-top: 1px solid $color-grey-02;
    }
    #frankenmenu-list .mega-menu-submenu .menu-item:last-child {
        border-bottom: 1px solid $color-grey-02;
    }
    #frankenmenu-list .mega-menu-submenu a {
        color: #000;
        padding: 5px 0;
    }

}

/** Mobile menu only
===================================*/
@include screen-menu-mobile {

    /* Prevents font-boosting of menu items on Android */
    #frankenmenu * {
        max-height: 999999px;
    }

    #wrap {
        margin-top: var(--header-height);
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        background-color: $color-white;
    }

    #frankenmenu {
        top: 0;
        position: fixed;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        background-color: $color-white;
        width: 70%;
        z-index: 0;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        padding-top: var(--header-height);
        z-index: $header-index - 1;
        border-left: 1px solid $color-grey-02;
    }

    #frankenmenu .container {
        position: relative;
        padding: 0;
        max-width: none;
        width: 100%;
        margin: 0;
        opacity: 0;
    }

    /* ---- Mobile header ---- */
    #mobile-header {
        background-color: $color-grey-07;
        height: var(--header-height);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: $header-index;
        border: none;
        padding-top: 20px;
        padding-bottom: 20px;
        transition:
            height $mobile-menu-transition,
            background-color $mobile-menu-transition;
    }
    #mobile-header .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    #mobile-header .fusion-mobile-menu-icons a {
        width: 40px;
        height: 40px;
        display: inline-grid;
        justify-content: center;
        align-items: center;
        font-size: 30px;
    }
    #mobile-header .fusion-mobile-menu-icons > button {
        margin-left: 8px;
    }
    #mobile-menu-button {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: none;
        border: none;
        padding: 0;
        text-indent: -9999px;
        width: 40px;
        height: 36px;
        margin-top: 2px;
        position: relative;
        background: none;

        &:hover,
        &:focus,
        &:active {
            .mobile-menu-button-top,
            .mobile-menu-button-middle,
            .mobile-menu-button-bottom {
                background-color: #1d5d40;
            }
        }
    }

    .mobile-menu-button-top,
    .mobile-menu-button-middle,
    .mobile-menu-button-bottom {
        height: 4px;
        border-radius: 2px;
        background-color: $color-primary;
        left: 6px;
        animation-fill-mode: forwards;
        animation-duration: 400ms;
        position: absolute;
        display: block;
        right: 6px;
    }
    .mobile-menu-button-top {
        top: 8px;
    }
    .mobile-menu-button-middle {
        top: 17px;
    }
    .mobile-menu-button-bottom {
        top: 26px;
    }

    /* Animations active */
    .frankenmenu-mob-menu-animations .mobile-menu-button-top {
        animation-name: mobileMenuBtnTopClose;
    }
    .frankenmenu-mob-menu-animations .mobile-menu-button-middle {
        animation-name: mobileMenuBtnMiddleClose;
    }
    .frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
        animation-name: mobileMenuBtnBottomClose;
    }

    /* Close button */
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
        animation-name: mobileMenuBtnTopOpen;
    }
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
        animation-name: mobileMenuBtnMiddleOpen;
    }
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
        animation-name: mobileMenuBtnBottomOpen;
    }

    @keyframes mobileMenuBtnTopClose {
        0% {
            transform: translate(0, 9px) rotate(45deg);
        }
        50%, 70% {
            transform: translate(0, 9px);
        }
        100% {
            transform: translate(0, 0);
        }
    }
    @keyframes mobileMenuBtnTopOpen {
        0% {
            transform: translate(0, 0);
        }
        50%, 70% {
            transform: translate(0, 9px);
        }
        100% {
            transform: translate(0, 9px) rotate(45deg);
        }
    }
    @keyframes mobileMenuBtnBottomClose {
        0% {
            transform: translate(0, -9px) rotate(-45deg);
        }
        50%, 70% {
            transform: translate(0, -9px);
        }
        100% {
            transform: translate(0, 0);
        }
    }
    @keyframes mobileMenuBtnBottomOpen {
        0% {
            transform: translate(0, 0);
        }
        50%, 70% {
            transform: translate(0, -9px);
        }
        100% {
            transform: translate(0, -9px) rotate(-45deg);
        }
    }
    @keyframes mobileMenuBtnMiddleClose {
        0% {
            opacity: 0;
        }
        50%, 70%, 100% {
            opacity: 1;
        }
    }
    @keyframes mobileMenuBtnMiddleOpen {
        0% {
            opacity: 1;
        }
        50%, 70%, 100% {
            opacity: 0;
        }
    }


    /* ---- All level menu items ---- */
    #frankenmenu-list, #frankenmenu-list ul {
        width: 100%;
    }
    #frankenmenu-list .menu-item {
        position: relative;
        float: none;
        width: 100%;
    }
    #frankenmenu-list a {
        min-height: 40px;
        width: 100%;
        padding: 9px 64px 9px 16px;
        color: $color-grey-06;
        background-color: $color-white;
        border-bottom: 1px solid $color-grey-02;

        &:hover,
        &:focus,
        &:active {
            background-color: #1d5d40;
            border-color: #1d5d40;
            color: $color-white;
        }
    }
    #frankenmenu-list .menu-item.menu-current-item > a {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
    }

    /* ---- First level menu ---- */
    #frankenmenu-list ul > .menu-item:last-child {
        border-bottom: none;
    }

    /* ---- Submenus ---- */
    #frankenmenu-list .sub-menu {
        display: none;
    }
    #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
        display: block;
    }

    /* Depth differences */
    #frankenmenu-list .menu-item-depth1 > a {
        padding-left: 16px;
    }
    #frankenmenu-list .menu-item-depth2 > a  {
        padding-left: 24px;
    }
    #frankenmenu-list .menu-item-depth3 > a  {
        padding-left: 32px;
    }
    #frankenmenu-list .menu-item-depth4 > a  {
        padding-left: 40px;
    }
    #frankenmenu-list .menu-item-depth5 > a  {
        padding-left: 48px;
    }
    #frankenmenu-list .menu-item-depth6 > a  {
        padding-left: 56px;
    }

    /* Expand buttons */
    .submenu-toggle {
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        border: none;
        width: 56px;
        background: none;
        height: 43px;
        display: block;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        padding: 0;

        &:hover,
        &:focus,
        &:active {
            background-color: $color-grey-06;

            &:before,
            &:after {
                background-color: $color-white;
            }
        }
    }

    .submenu-toggle:before,
    .submenu-toggle:after {
        content: " ";
        width: 12px;
        height: 3px;
        display: block;
        position: absolute;
        background-color: $color-grey-06;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 22px;
        transition:
            top 400ms ease,
            transform 400ms ease;
    }
    .submenu-toggle:before {
        transform: rotate(45deg) translateX(-5px);
    }
    .submenu-toggle:after {
        transform: rotate(-45deg) translateX(5px);
    }
    .menu-item-submenu-open > .submenu-toggle:before,
    .menu-item-submenu-open > .submenu-toggle:after {
        top: 15px;
    }
    .menu-item-submenu-open > .submenu-toggle:before {
        transform: rotate(-45deg) translateX(-5px);
    }
    .menu-item-submenu-open > .submenu-toggle:after {
        transform: rotate(45deg) translateX(5px);
    }
    .menu-item.menu-current-item .submenu-toggle:before,
    .menu-item.menu-current-item .submenu-toggle:after {
        background-color: $color-white;
    }

    .menu-item-has-children a:focus + .sub-menu + .submenu-toggle {
        &:before,
        &:after {
            background-color: $color-white;
        }
    }

    /* ---- Sub-navigation ---- */
    #frankenmenu-subnav-list {
        display: none;
    }

    /* ---- Menu positions ---- */

    /* Left */
    .frankenmenu-mobile-pos-left #frankenmenu {
        left: -70%;
    }
    .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #frankenmenu {
        left: 0;
    }
    .frankenmenu-mobile-pos-left #frankenmenu .container {
        transform: translate3d(-10%, 0, 0) scale(0.9);
    }
    .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
        transform: translate3d(70%, 0, 0);
    }

    /* Right */
    .frankenmenu-mobile-pos-right #frankenmenu {
        right: -70%;
    }
    .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #frankenmenu {
        right: 0;
    }
    .frankenmenu-mobile-pos-right #frankenmenu .container {
        transform: translate3d(10%, 0, 0) scale(0.9);
    }
    .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
        transform: translate3d(-70%, 0, 0);
    }
    .frankenmenu-mobile-pos-right #mobile-menu-button {
        float: right;
    }

    /* ---- Transitions ---- */
    #frankenmenu {
        transition:
            left $mobile-menu-transition,
            right $mobile-menu-transition,
            opacity $mobile-menu-transition;
    }
    #wrap {
        transition:
            transform $mobile-menu-transition;
    }
    #frankenmenu .container {
        transition:
            transform $mobile-menu-transition,
            opacity $mobile-menu-transition;
    }
    .js #frankenmenu {
        opacity: 0;
    }

    /* Menu open */
    .frankenmenu-mob-menu-visible #frankenmenu {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    .frankenmenu-mob-menu-visible #frankenmenu .container {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }

    /* ---- Moved elements ---- */
    .frankenmove {
        display: none;
    }

    /* ---- Megamenu ---- */
    .mega-menu-preview {
        display: none;
    }
    .mega-menu-preview-image {
        background-image: none !important;
    }
    .mega-menu-column-title {
        color: $color-white;
        font-size: $font-body;
        padding: 3px 20px;
        margin: 0;
        background: rgba(0,0,0,0.3);
    }
    #frankenmenu-list .mega-menu {
        display: none;
    }
    #frankenmenu-list .mega-menu ul {
        display: block;
    }

    .header-shrink {
        #mobile-header {
            height: var(--header-height-shrink);
        }

        #wrap {
            margin-top: var(--header-height-shrink);
        }
    }
}


/* --------------------------------
Menu search icon
---------------------------------- */
#frankenmenu-list .menu-item-depth1.menu-item-desktop-search {
    position: relative;
    margin-left: 30px;
    padding-right: 0;

    a {
        align-self: center;
        border: 1px solid $color-grey-05;
        color: $color-grey-09;
        border-radius: 30px;
        height: 30px;
        width: 30px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 28px;
        font-family: icomoon;
        cursor: pointer;
        padding: 0;

        &:after {
            color: #8d8d8d;
        }

        &:after {
            border-radius: 50% 50% 50% 50%;
            content: "\f002";
        }
    }

    .fusion-main-menu .fusion-main-menu-icon:after {
        color: #1e1e1e;
    }

    @include screen-menu-desktop {
        display: flex;
    }

    @include screen-menu-mobile {
        display: none;
    }
}

.home #frankenmenu-list .menu-item-depth1.menu-item-desktop-search a {
    border-color: $color-white;

    &:after {
        color: $color-white;
    }

}


/* --------------------------------
Fusion custom search menu
---------------------------------- */
.fusion-custom-menu-item-contents {
    display:none;
    padding:25px;
    text-align:right;
    border:1px solid transparent;
    width: 250px;
    background-color: #fff;
    border-color: #e7e7e7;

    position: absolute;
    left: auto;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s ease-in;

    @include screen-menu-mobile {
        top: var(--header-height);
        width: 100%;
        background: $color-grey-07;
        border-color: $color-grey-07;
    }

    @include screen-menu-desktop {
        top: 100%;
    }
}

.header-shrink .fusion-custom-menu-item-contents {
    @include screen-menu-mobile {
        top: var(--header-height-shrink);
    }
}

.fusion-main-menu-search-open .fusion-custom-menu-item-contents {
    display: block;
    visibility: visible;
    opacity: 1;
    z-index: 11;

    @include screen-menu-mobile {
        animation: fadeInDown 200ms ease;
        animation-fill-mode: forwards;
    }
}

// body.side-header-left #side-header .fusion-main-menu .fusion-custom-menu-item-contents {
//     top:0
// }

// #side-header .fusion-main-menu .fusion-main-menu-search .fusion-custom-menu-item-contents{
//     border-top-width:1px;
//     border-top-style:solid
// }

// #side-header .fusion-custom-menu-item-contents{
//     right:auto!important;top:0
// }

// .fusion-secondary-menu .fusion-custom-menu-item-contents{
//     position:absolute;left:-1px;visibility:hidden;opacity:0;transition:opacity .2s ease-in
// }

// .fusion-menu-cart.fusion-active-link .fusion-custom-menu-item-contents,
// .fusion-menu-cart:hover .fusion-custom-menu-item-contents{
//     visibility:visible;opacity:1
// }

// .fusion-main-menu .fusion-active-link .fusion-custom-menu-item-contents,
// .fusion-main-menu .fusion-active-link>.sub-menu,
// .fusion-secondary-menu .fusion-active-link .fusion-custom-menu-item-contents,
// .fusion-secondary-menu .fusion-active-link>.sub-menu{
//     opacity:1;visibility:visible
// }

// .fusion-main-menu .fusion-active-login,
// .fusion-secondary-menu .fusion-active-login{
//     overflow:visible
// }

// .fusion-main-menu .fusion-active-login .fusion-custom-menu-item-contents,
// .fusion-main-menu .fusion-active-login .sub-menu,
// .fusion-secondary-menu .fusion-active-login .fusion-custom-menu-item-contents,
// .fusion-secondary-menu .fusion-active-login .sub-menu{
//     opacity:1;visibility:visible
// }

// .fusion-menu-login-box .fusion-custom-menu-item-contents{
//     left:0;border:1px solid transparent;width:187px;padding:20px
// }

// .fusion-menu-login-box:hover .fusion-custom-menu-item-contents{
//     visibility:visible;opacity:1
// }

// .side-header-right #side-header .fusion-main-menu-search .fusion-custom-menu-item-contents{
//     left:-250px
// }

// body.side-header-left #side-header .fusion-main-menu .fusion-custom-menu-item-contents,
// body.side-header-left #side-header .fusion-main-menu>ul>li>ul{
//     left:calc(280px - 1px)
// }

// .fusion-main-menu .fusion-main-menu-search .fusion-custom-menu-item-contents{
//     width:250px
// }

// .fusion-main-menu .fusion-custom-menu-item-contents{
//     font-family:Roboto;font-weight:300
// }

// .fusion-main-menu .fusion-main-menu-cart .fusion-custom-menu-item-contents,
// .fusion-main-menu .fusion-main-menu-search .fusion-custom-menu-item-contents,
// .fusion-main-menu .fusion-menu-login-box .fusion-custom-menu-item-contents{
//     background-color:#fff;border-color:#e7e7e7
// }

// .side-header-right #side-header .fusion-main-menu .fusion-menu-login-box .fusion-custom-menu-item-contents {
//     left:calc((180px) * -1)
// }

// .fusion-secondary-menu .fusion-custom-menu-item-contents{
//     background-color:#fff;color:#fff;width:180px;border-color:#e5e5e5
// }

@mixin reverse-text {
    color: $color-white;

    & a {
        color: inherit;
        opacity: 1;
    }

    & a:hover,
    & a:focus,
    & a:active {
        color: inherit;
        opacity: .75;
    }

    h2, .h2,
    h3, .h3 {
        color: inherit;
    }
}

@mixin default-text {
    color: $color-body-font;

    & a {
        color: $color-primary;
    }

    & a:hover,
    & a:focus,
    & a:active {
        color: darken($color-accent-01, 20%);
        opacity: 1;
    }

    h2, .h2,
    h3, .h3 {
        color: $color-primary;
    }
}

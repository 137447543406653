[type="search"] {
    outline-offset: -2px;
}

.searchform {

    .fusion-search-form-content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        overflow: hidden;
        width: 100%;

        .fusion-search-field {
            flex-grow: 1;

            input {
                color: $color-grey-05;
                background-color: $color-white;
                border: 1px solid $color-grey-03;

                font-size: 13px;
                padding: 0 15px;
                height: 29px;
                width: 100%;
                box-sizing: border-box;
                margin: 0;
                outline: 0;
            }
        }
    }

    .fusion-search-button button {
        -webkit-appearance: none;
        outline: 0;
        background: #000;
        border: none;
        border-radius: 0;
        color: #fff;
        font-size: 1em;
        margin: 0;
        padding: 0;
        text-indent: 0;
        transition: all .2s;
        line-height: 29px;
        width: 29px;
        height: 29px;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.fusion-search-button button {
    -webkit-appearance: none;
    cursor: pointer;
    font-family: icomoon;
    font-weight: 400;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
}

// .fusion-search-form-classic {
//     .searchform .fusion-search-form-content .fusion-search-field input {
//         border-right: none;
//         border-top-right-radius: 0;
//         border-bottom-right-radius: 0;
//     }
// }

.fusion-live-search {
    .fusion-search-field {
        z-index: 14;
    }

    .fusion-search-button {
        z-index: 14;
        position: relative;
    }
}

// .fusion-search-form-classic .searchform .fusion-search-form-content,
// input[type=tel] {
//     border-radius: 0;
//     border-width: 1px;
// }



/* -----------------------------
File Path
-------------------------------- */
$file-path: '../assets/';


/* -----------------------------
Spacing
-------------------------------- */
$spacing:             8px;

$spacing--xxsmall:            $spacing * 0.25;

$spacing--xsmall:             $spacing * 0.5;

$spacing--small:              $spacing;

$spacing--medium:             $spacing*2;

$spacing--large:              $spacing*3;

$spacing--xlarge:             $spacing*4;

$spacing--xxlarge:            $spacing*8;


/* -----------------------------
style variables
-------------------------------- */
$standard-radius:            6px; // note: button radius set manually

$form-spacing:            8px;


/* -----------------------------
Header
-------------------------------- */
$header-transition: 350ms ease;

$header-index: 100;

/* -----------------------------
Page
-------------------------------- */
$max-page-width: 2400px;

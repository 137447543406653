.js .expando {
    border: 1px solid transparent;
    border-top: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* - Expanded - */
.js .expando[aria-expanded="true"] {
    clip: auto;
    height: auto;
    margin: 0 0 var(--paragraph-break);
    overflow: visible;
    position: relative;
    width: auto;
    padding: 1em calc(1em + 8px) 1em 38px;
    border-color: $color-grey-04;
    background-color: transparent;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    animation: fadeInDown 200ms ease;
    animation-fill-mode: forwards;

    .expando-open__icon:before {
        transform: rotate(-45deg);
    }

    .expando-open__icon:after {
        transform: rotate(45deg);
    }
}


.js .expando p:last-of-type {
    margin-bottom: 0;
}

/* - Open button - */
.expando-trigger {
    position: relative;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: none;
    padding: 10px calc(1em + 8px) 10px 8px;
    font-family: $accent-font;
    cursor: pointer;
    line-height: inherit;
    color: $color-white;
    font-weight: $fw-semibold;
    font-size: 18px;
    background: $color-grey-04;
    border-radius: $standard-radius;
    display: flex;
    align-items: center;
    transition:
        color $link-transition,
        background-color $link-transition,
        border-radius $link-transition;

    /* Hover */
    &:hover,
    &:focus {
        background: $color-grey-06;
    }

    &[aria-pressed="true"] {
        background: $color-primary;
        margin-bottom: 0;
        outline: 1px dashed $color-grey-02;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .expando-open__icon:after {
            transform: rotate(0);
        }
    }
}

.expando-open__icon {
    position: relative;
    margin-right: 4px;
    background-color: transparent;
    width: 26px;
    height: 26px;

    &:before,
    &:after {
        content: " ";
        width: 14px;
        height: 2px;
        background-color: $color-white;
        display: block;
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transition:
            transform $link-transition,
            background-color $link-transition;
    }

    &:after {
        transform: rotate(90deg);
    }
}


/* - Close button - */
.expando__close {
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: none;
    text-indent: -9999px;
    padding: 0;
    height: 26px;
    width: 26px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;

    &:before,
    &:after {
        content: " ";
        width: 14px;
        height: 2px;
        background-color: $color-grey-06;
        display: block;
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: transform 200ms ease;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }

    /* Hover */
    &:hover:before,
    &:hover:after {
        background-color: $color-grey-09;
    }
}



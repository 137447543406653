/* ---- Row - nowrap  ---- */
.row-nowrap {
    flex-wrap: nowrap;

    &--xsm {
        @include screen-560 {
            flex-wrap: nowrap;
        }
    }

    &--sm {
        @include screen-768 {
            flex-wrap: nowrap;
        }
    }

    &--md {
        @include screen-992 {
            flex-wrap: nowrap;
        }
    }

    &--lg {
        @include screen-1200 {
            flex-wrap: nowrap;
        }
    }

    &--lg2 {
        @include screen-1400 {
            flex-wrap: nowrap;
        }
    }

    &--xlg {
        @include screen-1600 {
            flex-wrap: nowrap;
        }
    }
}

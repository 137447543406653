
.field-element--upload {
    position: relative;
    cursor: pointer;
    &:hover {
        cursor: pointer;
    }

    .no-js & {
        .field-label__click-area {
            display: none;
        }
    }
    .field-label {
        position: relative;
        display: block;
        cursor: pointer;
        display: grid;
        grid-template-columns: auto 0;
        transition: grid-template-columns $link-transition;

        label {
            position: relative;
        }

        &__click-area {
            font-size: 1.4rem;
            position: absolute;
            top: calc(100% + #{$spacing});
            left: 0;
            right: 0;
            cursor: pointer;

            padding-left: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &:before {
                cursor: pointer;
                content: "Select a file";
                margin-right: $spacing*2;
                padding: $spacing*2;
                min-width: $spacing*11;
                background: $color-primary;
                border-radius: $standard-radius;
                color: $color-white;
            }

            &__status {
                cursor: pointer;
                flex: 1 1 auto;
                content: "No file selected";
                border: 2px solid $color-grey-02;
                border-radius: $standard-radius;
                background-color: $color-grey-01;
                padding: $spacing $spacing*2;
                height: $spacing*6;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    &.field-element--upload-has-val .field-label {
        grid-template-columns: auto $spacing*6;
    }

    .field-input {
        .no-js & {
            border: 2px solid $color-grey-02;
            background-color: $color-grey-01;
            border-radius: $standard-radius;
        }
        input {
            display: block;
            padding: $spacing;
            min-height: calc(#{$spacing*6} + 1px);
            width: 100%;

            .js & {
                // hide input but leave focus outline
                background-color: transparent;
                color: transparent;
            }
            .js &::-webkit-file-upload-button {
                opacity: 0;
            }

        }

    }
    .field-clear-btn {
        .no-js & {
            display: none;
        }

        background: url(../images/icon-system/icon_form_cross.svg) no-repeat center center;
        background-size: $spacing*4 auto;
        display: block;
        width: 0;
        height: $spacing*6;
        position: absolute;
        bottom: 0;
        right: 0;
        text-indent: -100vw;
        opacity: .25;
        transition:
            opacity $link-transition,
            width $link-transition;

        &:hover {
            opacity: 1;
        }
    }
    &.field-element--upload-has-val .field-clear-btn {
        width: $spacing*6;
    }

}



/* ---- white ---- */
.field-element--white.field-element--upload {

    .no-js & .field-input {
        border: 2px solid $color-grey-02;
        background-color: $color-white;
        border-radius: $standard-radius;
    }

    .field-label__click-area__status {
        border: 2px solid $color-grey-02;
        background-color: $color-white;
    }
}


/* ---- Error ---- */
.field-element--upload.field-element--error {
    .field-label__click-area__status {
        border-color: #c32c2a;
        background-color: lighten($color-utility-warning, 62%);
    }
    .no-js & .field-input {
        border-color: #c32c2a;
        background-color: lighten($color-utility-warning, 62%);
    }

}



.row--vertical-gutters *[class*="col-xs"],
.row--vertical-gutters *[class*="col-sm"],
.row--vertical-gutters *[class*="col-md"],
.row--vertical-gutters *[class*="col-lg"] {
    padding-top: 1rem;
    padding-bottom: 1rem;
}


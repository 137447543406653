/* - Date/time picker - */
.field-element--datetimepicker .field-input,
.field-element--datetimerangepicker .field-input {
    position: relative;
}

.field-element--datetimepicker .field-input:before,
.field-element--datetimerangepicker .field-input:before {
    content: "\00a0";
    display: block;
    position: absolute;
    left: calc(#{$form-spacing}/4);
    top: calc(50% - 12px);
    width: $form-spacing*5;
    background-image: url(#{$file-path}icon-system/icon_form_datetimepicker.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border-right: 1px solid $color-grey-04;
}

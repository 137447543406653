
.old-browser__banner {
    display: none;
    background: #cc1e34;
    position: fixed;
    padding: 10px 20px;
    color: #fff;
    top: 0;
    left: 0;
    right: 0;
    height: 40%;
    width: 100%;
    z-index: 9999;
}

.old-browser__banner a {
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
}

.old-browser__banner a:hover,
.old-browser__banner a:focus,
.old-browser__banner a:active {
    color: #fff;
    text-decoration: none;
}

.old-browser__banner .old-browser__center {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    margin: auto;
}

.old-browser .old-browser__banner {
    display: table;
}

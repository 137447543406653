* {
    margin: 0;
}

html,
body {
    background: $color-grey-03;
    height: 100%;
    scroll-behavior: smooth;
}

html {
    font-size: 62.5%;

    overflow-x:hidden;
    overflow-y:scroll;

    &.no-overflow-y {
        overflow-y: hidden;
    }
}


body {
    line-height: 1.8;
    font-size: $font-body;
    color: $color-body-font;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 300;
    letter-spacing: .5px;
    min-width:320px;

    -webkit-text-size-adjust:100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    margin:0;
}


#content {
    background-color: $color-white;
}

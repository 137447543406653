/* ---- Hidden ---- */
.-hidden {
    display: none !important;
    visibility: hidden;
}

/* ---- Invisible ---- */
.-invisible {
    visibility: hidden;
}

/* ---- Visibility Hidden ---- */
%vis-hidden,
.-vis-hidden {
    @include vis-hidden;
}

/* ---- Screen Readers ---- */
.screen-reader-text{
    border:0;clip:rect(1px,1px,1px,1px);-webkit-clip-path:inset(50%);clip-path:inset(50%);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute!important;width:1px;word-wrap:normal!important
}

.screen-reader-text:focus{
    background-color:#fff;border-radius:3px;box-shadow:0 0 2px 2px rgba(0,0,0,
    .6);clip:auto!important;-webkit-clip-path:none;clip-path:none;color:#333;display:block;font-size:1rem;font-weight:700;height:auto;left:5px;line-height:normal;padding:15px 23px 14px;text-decoration:none;top:5px;width:auto;z-index:100000
}

.contact-form {
    .tab {
        --gutter-width: 1rem;
        --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
        --half-gutter-width: calc((var(--gutter-width) * 0.5));

        box-sizing: border-box;
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: var(--gutter-compensation, -0.5rem);
        margin-left: var(--gutter-compensation, -0.5rem);
    }
}

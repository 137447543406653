.-ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
}

.-ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}
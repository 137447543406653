
hr {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 2rem 0;
    padding: 0;
}
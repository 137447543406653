.box {
    @include box;
}

.box--tiny {
    @include box('tiny');
}

.box--small {
    @include box('small');
}

.box--large {
    @include box('large');
}

.box-lined {
    @include box-lined;
}

.box-lined--grey {
    @include box-lined($color-grey-06);
}

.box-lined--primary {
    @include box-lined($color-primary);
}

.box-lined--secondary {
    @include box-lined($color-secondary);
}


.box-lined-bottom {
    @include box-lined($accentBorder: 'bottom');

    &.box-lined--grey {
        @include box-lined($color-grey-06, 'bottom');
    }

    &.box-lined--primary {
        @include box-lined($color-primary, 'bottom');
    }

    &.box-lined--secondary {
        @include box-lined($color-secondary, 'bottom');
    }
}

.box--drop-shadow {
    @include box;
    background: $color-white;
    box-shadow: $spacing $spacing $spacing*2 rgba($color-black, .2);
}

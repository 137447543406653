.pseudo-date-picker {
    @import "node_modules/@karmabunny/date-picker/dist/date-picker.min";

    @include form-textbox-styles;
    border: none;
    background-image: url(../assets/icon-system/icon_form_arrow-down.svg);
    background-repeat: no-repeat;
    background-position: center right $form-spacing;
    background-size: $form-spacing*2.5;
    border-radius: $standard-radius;
    background-color: $color-white;
    font-family: $primary-font;
}


.pseudo-date-picker {
    table, td, th {
        border: none;
    }

    td {
        &.depart {
            background: linear-gradient(to bottom right, #fff 0 50%, #ddd 50% 100%);
        }

        &.disabled:not(.time-travel) {
            opacity: 1;
            background-color: #eee;
            color: #bbb;
            &::after {
                display: none;
            }
        }

        &.in-range,
        &.start-range,
        &.end-range {
            background: #357ebd;
            color: #eee;
        }
    }

    .date-picker,
    .date-picker__input {
        width: 100%;
        max-width: 100%;
        border: none;
        background: none;
    }

    .date-picker__input {
        @include form-textbox;

        &:focus,
        &:active {
            outline: auto;
        }

    }
}

@mixin badge($background-color: $color-grey-01, $color: $color-grey-08) {
    display: inline-block;
    padding: .25em .4em;
    font-size: var(--milli-font-size);
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
	text-decoration: none;
    outline-offset: 2px;
    box-shadow: 0 0 0 transparent;
    background-color: $background-color;
    color: $color;
    transition:
        color $link-transition,
        outline $link-transition,
        background-color $link-transition,
        box-shadow $link-transition;

    &[href]:hover,
    &[href]:focus {
        outline: thin dotted $color-white;
        box-shadow: 0 2px 8px rgba($color-grey-09, 0.33);
    }

    &[href]:focus {
        outline: thin solid $color-white;
    }
}

.field-element--totalselector {
    position: relative;
}
.field-element--totalselector .field-input .total-selector__output {
    cursor: pointer;
    @include form-select-styles;
}

.field-element--totalselector__dropdown {
    position: absolute;
    background: $color-white;
    top: 100%;
    left: 0;
    right: 0;
    padding: $form-spacing*2 $form-spacing*2;
    margin-top: calc(#{$form-spacing}/4);
    border-radius: calc(#{$form-spacing}/2);
    z-index: 800;
    border: 1px solid $color-grey-04;
    display: none;
    white-space: nowrap;
}

.field-element--totalselector.field-element--totalselector--active .field-element--totalselector__dropdown {
    display: block;
}

.field-element--totalselector__dropdown:before {
    @extend %form-field-pseudo-anchor--before;
}

.field-element--totalselector__dropdown:after {
    @extend %form-field-pseudo-anchor--after;
}

.total-selector__dropdown__field {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 0 $form-spacing*2;
}

.total-selector__dropdown__field:last-child {
    margin-bottom: 0;
}

.total-selector__dropdown__field__labels {
    display: flex;
    align-items: center;
}

.total-selector__dropdown__field__buttons {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.total-selector__dropdown__field__button {
    -webkit-appearance: none;
    border: 1px solid $color-grey-05;
    border-radius: 50%;
    outline: none;
    background: none;
    padding: 0;
    height: $form-spacing*4;
    width: $form-spacing*4;
    color: $color-grey-06;
    cursor: pointer;
    font-size: 1.4rem;
    flex: 0 0 auto;
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--decrease {
    background-image: url(../assets/icon-system/icon_form_minus.svg);
    background-position: left $form-spacing -1 center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--increase {
    background-image: url(../assets/icon-system/icon_form_plus.svg);
    background-position: right $form-spacing -1 center;
}

.total-selector__dropdown__field__button:focus {
    color: $color-grey-06;
    border-color: $color-grey-06;
    background-color:$color-grey-01;
}

.total-selector__dropdown__field__button:hover {
    color: $color-grey-06;
    border-color: $color-grey-06;
}

/* Min / max */
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--min,
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--max {
    opacity: 0.5;
    pointer-events: none;
    color: $color-grey-06;
    border-color: $color-grey-03;
}

.total-selector__dropdown__field__total {
    padding: $form-spacing*0.5 $form-spacing*0.75 !important;
    width: 50px !important;
    text-align: center;
    font-size: 1.8rem;
    border: 0 !important;
}

.total-selector__dropdown__field__labels p {
    display: block;
    margin-right: $form-spacing;
}
.total-selector__dropdown__field__labels__title {
    margin: 0;
    font-size: 1.6rem;
}

.total-selector__dropdown__field__labels__helper {
    margin: 0;
    font-size: $font-small;
    display: block;
}

.field-element--totalselector__fields {
    display: none;
}

/* Done button */
.total-selector__dropdown__close__button-wrap {
    text-align: right;
    padding-top: $form-spacing +2;
}

/* BP Extra small */
@include screen-560 {
    .field-element--totalselector__dropdown {
        right: auto;
        min-width: 320px;
    }
    .total-selector__dropdown__field__labels__helper {
        font-size: 1.4rem;
        line-height: 1;
    }
    .total-selector__dropdown__field {
        margin: 0 0 $form-spacing*3;
    }
}

/* BP Small */
@include screen-768 {
    /* Don't really need this close button above tablet */
    .total-selector__dropdown__close__button-wrap {
        display: none;
    }
}

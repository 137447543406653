blockquote {
    margin-top: 20px;
    position: relative;
    background-color: $color-grey-01;
    color: $color-grey-006;
    border-radius: 0;
    padding: 50px 30px 40px;
    font-style: normal;
    line-height: 1.6;
    overflow: visible;

    &:after {
        content:'';
        position:absolute;
        top:-20px;
        right:20px;
        display:block;
        width:58px;
        height:48px;
        background:url(../assets/img_blockquote.svg);
        background-size:58px 48px;
        background-repeat:no-repeat;
        background-position:center
    }
}

blockquote *:first-child {
    margin-top: 0;
}

blockquote *:last-child {
    margin-bottom: 0;
}

@include screen-992 {
    .blockquote--left {
        float: left;
        width: 45%;
        margin-left: 0px;
        margin-right: 20px;
        clear: right;
    }

    .blockquote--right {
        float: right;
        width: 45%;
        margin-left: 20px;
        margin-right: 0px;
        clear: left;
    }
}

/* ---- Text selection ---- */
::-moz-selection {
	color: #000;
	background: $color-utility-selection;
}

::selection {
	color: #000;
	background: $color-utility-selection;
}

:root {
    --default-container: 88%;
    --default-container-gutter: 6vw;
}

@include screen-768 {
    :root {
        --default-container: 92%;
        --default-container-gutter: 4vw;
    }
}

@include screen-1200 {
    :root {
        --default-container: 1140px;
        --default-container-gutter: calc(50vw - (var(--default-container) / 2));
    }
}

@include screen-2400 {
    :root {
        --default-container-gutter: calc((#{$max-page-width} - var(--default-container)) / 2);
    }
}


.container {
    width: var(--default-container);
    margin: 0 auto;
}

/* [01] The bleed utility classes now live in the background colours file */


/* fieldset styles */
fieldset {
   border: none;
   padding: 0;
   margin: 0;
}

.field-element--fieldset-columns .field-element__input-set {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    column-gap: $spacing*2;
}

/* Hide checkbox and radio field element for custom fields structured like Sprout3 CMS fields) */
.field-element--checkboxboollist input[type="checkbox"],
.field-element--checkboxset input[type="checkbox"],
.field-element--multiradio input[type="radio"] {
    @include vis-hidden;
}

.field-element input[type="checkbox"] + label,
.field-element input[type="radio"] + label,
.field-element--small input[type="checkbox"] + label,
.field-element--small input[type="radio"] + label {
    padding-left: $form-spacing*4;
    position: relative;
    cursor: pointer;
    display: inline-block;
}

.field-element--radio_group,
.field-element--checkbox_group {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: calc(1.2em + #{$form-spacing});
        bottom: 0;
        left: var(--half-gutter-width);
        right: var(--half-gutter-width);
        border: 1px solid $color-grey-03;
        border-radius: $standard-radius;
        pointer-events: none;
        z-index: 0;
    }

    label {
        position: relative;
        z-index: 2;
        display: block;
        padding: 0 10px;
        font-weight: $fw-normal;

        &:nth-child(2) {
            padding-top: calc(5px + #{$form-spacing});
        }

        &:last-child {
            padding-bottom: 5px;
        }

        .field-input {
            margin-right: 8px;
        }
    }

    label.field-label {
        position: relative;
        z-index: 1;
        padding: 0 5px;
        font-weight: $fw-bold;
        background-color: $color-white;
    }
}

.field-element--checkbox {
    label {
        font-weight: $fw-normal;
    }

    .field-input {
        margin-right: 8px;
    }
}

/* -- Field set pseudo elements -- */
.field-element input[type="checkbox"] + label:before,
.field-element input[type="radio"] + label:before,
.field-element--small input[type="checkbox"] + label:before,
.field-element--small input[type="radio"] + label:before {
    content: " ";
    display: inline-block;
    width: $form-spacing*3;
    height: $form-spacing*3;
    margin-right: $form-spacing*1.5;
    position: relative;
    top: calc(#{$form-spacing}/2);
    background-color: $color-grey-01;
    border: 1px solid $color-grey-04;
    margin-left: -$form-spacing*4;
}

/* Radio button (pseudo element) */
.field-element input[type="radio"] + label:before,
.field-element--small input[type="radio"] + label:before {
    border-radius: $form-spacing*1.5;
}

/* -- Field set pseudo elements : checked -- */
.field-element input[type="checkbox"]:checked + label:after,
.field-element input[type="radio"]:checked + label:after,
.field-element--small input[type="checkbox"]:checked + label:after,
.field-element--small input[type="radio"]:checked + label:after {
    content: " ";
    position: absolute;
    left: 1px;
    top: $form-spacing*0.75;
}
/* Checkbox box checked (pseudo element) */
.field-element input[type="checkbox"]:checked + label:after,
.field-element--small input[type="checkbox"]:checked + label:after {
   content: "\00a0";
   background-image: url(#{$file-path}icon-system/icon_form_tick-md.svg);
   background-position: left top;
   background-repeat: no-repeat;
   background-size: 90%;
   width: $form-spacing*3;
   height: $form-spacing*3;
}

/* Radio dot (pseudo element) */
.field-element input[type="radio"]:checked + label:after,
.field-element--small input[type="radio"]:checked + label:after {
    left: $form-spacing - 1;
    top: $form-spacing *1.5 - 1;
    width: $form-spacing*1.2;
    height: $form-spacing*1.2;
    border-radius: $form-spacing*0.6;
    background: $color-grey-07;
}

/* White */
.field-element--white input[type="checkbox"] + label:before,
.field-element--white input[type="radio"] + label:before {
    background-color: $color-white;
}

/* input focus state */
.fieldset--multiradio:focus-within {
    outline: $color-grey-06 auto 5px;
}
.field-element input[type="checkbox"]:focus + label {
    outline: $color-grey-06 auto 5px;
    outline-offset: -3px;
}

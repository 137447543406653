:root {
    --section-small: 15px;
    --section: 20px;
    --section-large: 40px;
}

@include screen-360 {
    :root {
        --section-small: 24px;
        --section: 40px;
        --section-large: 80px;
    }
}

@include screen-768 {
    :root {
        --section-small: 40px;
        --section: 80px;
        --section-large: 120px;
    }
}

.section {
    padding-top: var(--section);
    padding-bottom: var(--section);
}

.section-small {
    padding-top: var(--section-small);
    padding-bottom: var(--section-small);

    &-t {
        padding-top: var(--section-small);
    }

    &-b {
        padding-bottom: var(--section-small);
    }
}

.section-large {
    padding-top: var(--section-large);
    padding-bottom: var(--section-large);
}

.section-t0 {
    padding-top: 0;
}

.section-b0 {
    padding-bottom: 0;
}


.fusion-social-networks {
    --header_social_links_font_size:16px;
    --header_social_links_boxed_padding:8px;
    --footer_social_links_font_size:16px;
    --footer_social_links_boxed_padding:8px
}

.fusion-social-network-icon{
    --header_social_links_icon_color:#fffcfc;
    --header_social_links_box_color:#e8e8e8;
    --header_social_links_boxed_radius:4px;
    --footer_social_links_icon_color:#46494a;
    --footer_social_links_box_color:#222222;
    --footer_social_links_boxed_radius:4px;
    --sharing_social_links_boxed_radius:4px
}

.fusion-social-networks{
    overflow:hidden
}

.fusion-social-networks .fusion-social-networks-wrapper{
    margin:0 -10px
}

.fusion-social-networks .fusion-social-network-icon{
    display:inline-block;margin:0 10px 10px;font-size:16px;color:#46494a;text-decoration:none;-webkit-box-shadow:none!important;box-shadow:none!important;line-height:1
}

.fusion-social-networks .fusion-social-network-icon:hover{
    opacity:.8
}

.fusion-social-networks .fusion-social-network-icon:before{
    color:inherit
}

.fusion-social-networks.boxed-icons a{
    text-align:center;-webkit-box-sizing:border-box;box-sizing:border-box;-webkit-box-shadow:none!important;box-shadow:none!important
}

.fusion-social-networks.boxed-icons .fusion-social-networks-wrapper{
    margin:0 -5px
}

.fusion-social-networks.boxed-icons .fusion-social-network-icon{
    margin:0 5px 10px;padding:8px;background-color:#46494a;border:none;border-radius:3px;background-clip:inherit;border:1px solid #46494a;color:#fff;text-decoration:none
}

.fusion-social-links-footer{
    display:table-cell;vertical-align:middle;width:40%;text-align:right
}

.fusion-social-links-footer .fusion-social-networks{
    margin-top:10px
}

.widget .fusion-social-networks .fusion-social-networks-wrapper{
    margin:0 -5.5px
}

.widget .fusion-social-networks .fusion-social-network-icon{
    margin:0 5.5px 14px
}

.widget .fusion-social-networks.boxed-icons .fusion-social-networks-wrapper{
    margin:0 -3.5px
}

.widget .fusion-social-networks.boxed-icons .fusion-social-network-icon{
    margin:0 3.5px 10px
}

.fusion-social-links .fusion-social-networks a{
    font-size:16px
}

.fusion-social-links .boxed-icons .fusion-social-networks-wrapper .fusion-social-network-icon{
    width:calc(16px + (2 * (8px)) + 2px)
}

.fusion-social-links .boxed-icons.fusion-social-networks a{
    padding:8px
}

.fusion-social-links-footer .fusion-social-networks.boxed-icons a.fusion-social-network-icon.fusion-custom.fusion-icon-custom {
    padding:0;background-color:transparent;border:none;border-radius:0;box-shadow:none
}

.fusion-social-links-footer .fusion-social-networks.boxed-icons a.fusion-social-network-icon.fusion-custom.fusion-icon-custom img {
    position:relative
}

.fusion-author-widget .fusion-social-networks .fusion-social-networks-wrapper .fusion-social-network-icon{
    margin:7px 5.5px
}

.fusion-author-widget .fusion-social-networks.boxed-icons .fusion-social-networks-wrapper .fusion-social-network-icon{
    margin:5px 3.5px
}

.fusion-author .fusion-social-networks{
    display:table-cell;vertical-align:middle;text-align:right;line-height:normal
}

.fusion-author .fusion-social-networks .fusion-social-network-icon{
    margin-bottom:0
}

.fusion-author .fusion-social-networks.boxed-icons .fusion-social-network-icon{
    margin-bottom:2px
}

.fusion-author-widget .fusion-social-networks{
    margin:-7px 0
}

.fusion-author-widget .fusion-social-networks.boxed-icons{
    margin:-5px 0
}

.fusion-social-links-footer .fusion-social-networks a{
    color:#FFFFFF!important
}

.fusion-social-links-footer .fusion-social-networks a{
    font-size:18px
}

.fusion-social-links-footer .fusion-social-networks a:hover{
    color:#666666!important
}

.contact-sidebar .fusion-text:nth-child(4) .fusion-social-networks{
    display:inline-block!important;vertical-align:middle;margin-left:20px
}

.db-tags-socials .single-social-share-bottom .fusion-social-networks{
    display:inline-block;
    float:right;
}

.fusion-social-links-footer .fusion-social-networks a{
    font-size:16px
}

.fusion-social-links-footer .fusion-social-networks a.custom{
    max-height:8px
}

.fusion-social-links-footer .fusion-social-networks a.custom img{
    max-height:16px
}

.fusion-social-links-footer .fusion-social-networks.boxed-icons a{
    width:calc(16px + (2 * (8px)) + 2px);padding:8px
}

.fusion-social-links-footer .fusion-social-networks.boxed-icons a.custom{
    top:calc((8px) * -1)
}

.fusion-social-links-footer .fusion-social-networks.boxed-icons a.custom img{
    max-height:calc(16px + (2 * (8px)) + 2px)
}

.fusion-social-links-footer .fusion-social-networks.boxed-icons .fusion-social-network-icon{
    color:#46494a
}

.fusion-social-links-footer .fusion-social-networks.boxed-icons .fusion-social-network-icon:not(.custom){
    background-color:#222;border-color:#222;border-radius:4px
}

.fusion-social-links-footer .fusion-social-networks .fusion-social-network-icon{
    color:#46494a
}

.fusion-social-networks.boxed-icons .fusion-social-network-icon:not(.custom){
    background-color:#222;border-color:#222
}

.fusion-sharing-box .fusion-social-networks a{
    font-size:16px
}

.fusion-sharing-box .fusion-social-networks.boxed-icons .fusion-social-network-icon:not(.custom){
    border-radius:4px
}

.fusion-sharing-box .fusion-social-networks.boxed-icons a{
    padding:8px
}


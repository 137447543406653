
.site-messages {
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        display: block;
        margin: 0 0 15px;
        padding: 10px 20px;
        vertical-align: bottom;
        text-align: left;
        font-size: 16px;
        font-size: 1.6rem;
        line-height: 1.2em;

        &:last-child {
            margin-bottom: 0;
        }

        &.site-messages__item--mb {
            margin-bottom: $font-body;
        }
    }
}

.site-messages .error {
    background: $color-utility-warning-subtle;
    border-left: 4px solid $color-utility-warning;
    color: #FFF !important;
}

.site-messages .confirm {
    color: #FFF !important;
    background: $color-utility-positive-subtle;
    border-left: 4px solid $color-utility-positive;
}

.site-messages .warning {
    color: #FFF !important;
    background: $color-utility-alert-subtle;
    border-left: 4px solid $color-utility-alert;
}

.site-messages a {
    color: $color-white;
}



.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

@include screen-560 {
    .text-align-right--xsm {
        text-align: right;
    }

    .text-align-left--xsm {
        text-align: left;
    }

    .text-align-center--xsm {
        text-align: center;
    }
}

@include screen-768 {
    .text-align-right--sm {
        text-align: right;
    }

    .text-align-left--sm {
        text-align: left;
    }

    .text-align-center--sm {
        text-align: center;
    }
}

/* BP medium */
@include screen-992 {
    .text-align-right--md {
        text-align: right;
    }

    .text-align-left--md {
        text-align: left;
    }

    .text-align-center--md {
        text-align: center;
    }
}

/* BP large */
@include screen-1200 {
    .text-align-right--lg {
        text-align: right;
    }

    .text-align-left--lg {
        text-align: left;
    }

    .text-align-center--lg {
        text-align: center;
    }
}

/* ---- Fusion ---- */
.fusion-row{
    display:flex;
    justify-content:space-between;
    align-items:center
}

.fusion-alignleft {
    float:left;
    margin-right:0;
    -ms-flex-positive:1;
    flex-grow:1
}

.fusion-alignright {
    float:right;
    margin-left:0;
    -ms-flex-positive:1;
    flex-grow:1
}

.fusion-alignright > * {
    float: right;
}

.button {
    @include button;
}

/* ---- Sizes ---- */
/* Small */
.button-small,
.button--small {
    @include button-width-small;
    @include button-height-small;
    @include button-text-small;
}

.button-large,
.button--large {
    @include screen-560 {
        @include button-width-large;
        @include button-height-large;
        @include button-text-large;
    }
}

.button-wide {
    @include button-width-large;
}

.button-tall {
    @include button-height-large;
}

/* - Button icons - */
.button__icon {
    display: inline-block;
    vertical-align: top;

    &--right {
        margin-left: 0.25em;
        margin-right: -0.25em;
    }
    &--left {
        margin-right: 0.25em;
        margin-left: -0.25em;
    }

    svg {
        height: 1em;
        width: 1em;

        @include svg-contents {
            fill: $color-white;
            transition: fill 250ms ease-in-out;
        }
    }
}

/* ---- Button Colours ---- */
.button--alt {
    @include button($color-primary);
}

.button--secondary {
    @include button($color-secondary);
}

.button--warning {
    @include button($color-utility-warning);
}

.button--neutral {
    @include button($color-grey-02, $color-grey-08);
}

.button--positive {
    @include button($color-utility-positive-subtle);
}


/* ---- Outline buttons ---- */
.button-outline {
    @include button-outline($color-primary);
}

/* ---- Button Variations ---- */
// block
.button--block {
    display: block;
    width: 100%;
}

// Disabled/unavailable button style
.button[disabled],
.button--disabled {
    border: none;
    box-shadow: none;
    cursor: default;
    opacity: 0.4;
    pointer-events: none;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        opacity: 0.4;
    }
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

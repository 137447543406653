.form__row {
    --gutter-width: 1rem;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));

    .form__column {
        &--align-left {
            display: flex;
            justify-content: flex-start;
        }

        &--align-right {
            display: flex;
            justify-content: flex-end;
        }

        &.field-element--html {
            margin-bottom: 0;

            h3 {
                margin-top: calc(var(--paragraph-break)/4);
                margin-bottom: calc(var(--paragraph-break)/2);
            }
        }
    }
}

.form__pages {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    li {
        @include button-base;
        flex: 0 1 100%;
        display: block;
        height: 64px;
        line-height: 64px;
        vertical-align: middle;
        transition: all 0.15s ease-in-out;
        cursor: default;
        text-decoration: none;
        text-align: center;
        transition: all 250ms ease;
        border: 0;
        outline: none;
        background-color: $color-white;
        color: $color-grey-06;

        margin-bottom: -1px;
        background-color: #fff;
        border: 1px solid $color-grey-04;
        text-decoration: none;

        @include screen-992 {
            flex: 1 1 144px;
            height: 100%;
            width: 144px;
            line-height: calc(var(--header-height) - 16px);
            padding: 8px;
            margin-bottom: 0;
            margin-right: -1px;
        }

        &:first-child {
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem;

            @include screen-992 {
                border-top-left-radius: .25rem;
                border-top-right-radius: initial;
                border-bottom-left-radius: .25rem;
            }
        }

        &:last-child {
            margin-bottom: 0;
            border-bottom-right-radius: .25rem;
            border-bottom-left-radius: .25rem;

            @include screen-992 {
                margin-right: 0;
                border-bottom-right-radius: .25rem;
                border-bottom-left-radius: initial;
                border-top-right-radius: .25rem;
            }
        }

        &.active {
            background-color: $color-primary;
            color: $color-white;
        }
    }
}

.flatpickr-calendar {
    font: 14px $primary-font;
}

button[name="form_previous_page_button"] {

}

button[name="form_page_submit"] {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;
}

.field-helper.field-helper--below {
    line-height: normal;
    margin-top: 0.5em;
    margin-bottom: 0;
    color: $color-grey-06;
}

.form__column[data-ff-rule] {
    border-left: 2px solid $color-primary;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}


.share-list {
    margin-top: $spacing*10;
    margin-bottom: -$spacing*4;
    border-top: 1px solid $color-grey-02;
    padding-top: $spacing*2;
    @include list-reset;
    display: flex;
    justify-content: space-between;

    &__item {
        display: inline-block;

        a {
            color: $color-grey-05;
            text-decoration: none;
            display: block;
            display: flex;
            align-items: center;

            &:hover,
            &:focus,
            &:active {
                color: $color-grey-08;

                svg {
                    @include svg-contents {
                        fill: $color-grey-08;
                    }
                }
            }

            svg {
                vertical-align: middle;
                margin-left: calc(#{$spacing}/2);

                @include svg-contents {
                    transition: fill $link-transition;
                    fill: $color-grey-04;
                }
            }
        }
    }
}


.highlight,
.highlight--right,
.highlight--left {
    margin: 0 0 var(--body-font-size);
    padding: 30px;
    background: $color-grey-01;
    clear: both;
}

/* BP medium */
@include screen-992 {
    .highlight--left {
        float: left;
        width: 45%;
        margin-right: 20px;
        clear: right;
    }

    .highlight--right {
        float: right;
        width: 45%;
        margin-left: 20px;
        clear: left;
    }
}

.highlight *:last-child,
.highlight--right *:last-child,
.highlight--left *:last-child {
    margin-bottom: 0;
}


.float-left,
.float-center,
.float-right {
    margin: 30px auto;
    display: block;
    clear: both;
}


/* BP Smaller */
@include screen-560 {
    .float-left {
        margin: 0 30px 30px 0;
        float: left;
    }

    .float-right {
        margin: 0 0 30px 30px;
        float: right;
    }
}


table {
    border-collapse: collapse;
    border: 1px solid $color-grey-05;
}

th,
td {
    padding: 5px 10px;
    border: 1px solid $color-grey-05;
}

th,
thead td {
    background: $color-grey-02;
}


:root {
    --body-font-size: 1.4rem;

    // headings
    --h1-font-size: 3.2rem;
    --h1-line-height: normal;

    --h2-font-size: 2.6rem;
    --h2-line-height: normal;

    --h3-font-size: 2.1rem;
    --h3-line-height: normal;

    --h4-font-size: 1.8rem;
    --h4-line-height: normal;

    --h5-font-size: 1.8rem;
    --h5-line-height: normal;

    --h6-font-size: 1.6rem;
    --h6-line-height: normal;

    /* [02] */
    // big
    --giga-font-size: 8rem;
    --mega-font-size: 6.4rem;
    --kilo-font-size: 5.6rem;

    // small
    --milli-font-size: 1rem;
    --centi-font-size: 1.2rem;
}

/*
Basic system font stacks
*/

$primary-font: 'Roboto', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;

$accent-font: 'Rubik', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;

@font-face {
    font-display:block;
    font-family:'Roboto';
    font-style:italic;
    font-weight:400;
    src:local('Roboto Italic'),local('Roboto-Italic'),url(//fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu51xIIzc.ttf) format('truetype')
}

@font-face {
    font-display:block;
    font-family:'Roboto';
    font-style:italic;
    font-weight:700;
    src:local('Roboto Bold Italic'),local('Roboto-BoldItalic'),url(//fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TzBic6CsE.ttf) format('truetype')
}

@font-face {
    font-display:block;
    font-family:'Roboto';
    font-style:normal;
    font-weight:300;
    src:local('Roboto Light'),local('Roboto-Light'),url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format('truetype')
}

@font-face {
    font-display:block;
    font-family:'Roboto';
    font-style:normal;
    font-weight:400;
    src:local('Roboto'),local('Roboto-Regular'),url(//fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype')
}

@font-face {
    font-display:block;
    font-family:'Roboto';
    font-style:normal;
    font-weight:700;
    src:local('Roboto Bold'),local('Roboto-Bold'),url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype')
}

@font-face {
    font-display:block;
    font-family:'Rubik';
    font-style:normal;
    font-weight:400;
    src:local('Rubik'),local('Rubik-Regular'),url(//fonts.gstatic.com/s/rubik/v8/iJWKBXyIfDnIV7nBrXk.ttf) format('truetype')
}

@font-face {
    font-display:block;
    font-family:'Rubik';
    font-style:normal;
    font-weight:500;
    src:local('Rubik Medium'),local('Rubik-Medium'),url(//fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7Eyjmmd8WU.ttf) format('truetype')
}

@font-face{
    font-display:swap;
    font-family:'PT Sans';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/ptsans/v16/jizaRExUiTo99u79D0KEwA.ttf) format('truetype')
}

@font-face{
    font-display:swap;
    font-family:'Roboto';
    font-style:italic;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/roboto/v29/KFOkCnqEu92Fr1Mu51xIIzc.ttf) format('truetype')
}

@font-face{
    font-display:swap;
    font-family:'Roboto';
    font-style:italic;
    font-weight:700;
    src:url(https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBic6CsE.ttf) format('truetype')
}

@font-face{
    font-display:swap;
    font-family:'Roboto';
    font-style:normal;
    font-weight:300;
    src:url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format('truetype')
}

@font-face{
    font-display:swap;
    font-family:'Roboto';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype')
}

@font-face{
    font-display:swap;
    font-family:'Roboto';
    font-style:normal;
    font-weight:700;
    src:url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype')
}

@font-face{
    font-display:swap;
    font-family:'Rubik';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/rubik/v19/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFV0Uw.ttf) format('truetype')
}

@font-face{
    font-display:swap;
    font-family:'Rubik';
    font-style:normal;
    font-weight:500;
    src:url(https://fonts.gstatic.com/s/rubik/v19/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYiFV0Uw.ttf) format('truetype')
}

/* Weights */

$fw-light: 300;

$fw-normal: 400;

$fw-semibold: 500;

$fw-medium: 700;

$fw-bold: 800;


/* Size */

$font-small: var(--centi-font-size);

$font-body: var(--body-font-size);


// Color

$color-body-font: $color-grey-09;



/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */

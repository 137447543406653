
/* [01]
    Bleeds commented so as to not bloat the css unnecessarily.
    Uncomment to use
*/

.bg-white {
    background-color: $color-white;
}

.bg-lightestgrey {
    background-color: $color-grey-01;

    &.container--bleed-left {
        @include bleed-left($color-grey-01);
    }
    &.container--bleed-right {
        @include bleed-right($color-grey-01);
    }
}

.bg-lightgrey {
    background-color: $color-grey-02;

    &.container--bleed-left {
        @include bleed-left($color-grey-02);
    }
    &.container--bleed-right {
        @include bleed-right($color-grey-02);
    }
}

.bg-darkgrey {
    background-color: $color-grey-07;

    @include reverse-text;
}

.bg-primary {
    background-color: $color-primary;

    @include reverse-text;

    /* [ 01 ]
    &.container--bleed-left {
        @include bleed-left($color-primary);
    }
    &.container--bleed-right {
        @include bleed-right($color-primary);
    }
    */
}

.bg-secondary {
    background-color: $color-secondary;

    /* [ 01 ]
    &.container--bleed-left {
        @include bleed-left($color-secondary);
    }
    &.container--bleed-right {
        @include bleed-right($color-secondary);
    }
    */
}

.bg-alternative {
    background-color: $color-alternative;

    /* [ 01 ]
    &.container--bleed-left {
        @include bleed-left($color-alternative);
    }
    &.container--bleed-right {
        @include bleed-right($color-alternative);
    }
    */
}

.bg-accent-01 {
    background-color: $color-accent-01;

    /* [ 01 ]
    &.container--bleed-left {
        @include bleed-left(color-accent-01);
    }
    &.container--bleed-right {
        @include bleed-right(color-accent-01);
    }
    */
}

.bg-accent-02 {
    background-color: $color-accent-02;

    /* [ 01 ]
    &.container--bleed-left {
        @include bleed-left(color-accent-02);
    }
    &.container--bleed-right {
        @include bleed-right(color-accent-02);
    }
    */
}

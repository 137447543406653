@mixin section-title {
    display: block;
    margin-top: 0;
    margin-bottom: 40px;
    line-height: normal;
    color: $color-primary;
}

@mixin subtitle {
    display: block;
    font-size: var(--centi-font-size);
    color: $color-grey-05;
    margin-bottom: 0;
    text-transform: uppercase;
}

@mixin h1($colour: inherit) {
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);

    @if $colour {
        color: $colour;
    }
}

@mixin h2($colour: inherit) {
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);

    @if $colour {
        color: $colour;
    }
}

@mixin h3($colour: inherit) {
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);

    @if $colour {
        color: $colour;
    }
}

@mixin h4($colour: inherit) {
    font-size: var(--h4-font-size);
    line-height: var(--h4-line-height);

    @if $colour {
        color: $colour;
    }
}

@mixin h5($colour: inherit) {
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
    margin-bottom: 0;

    @if $colour {
        color: $colour;
    }
}

@mixin h6($colour: inherit) {
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);

    @if $colour {
        color: $colour;
    }
}

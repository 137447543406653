:root {
    --paragraph-break: 20px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0 0 var(--paragraph-break) 0;
    font-family: $accent-font;
    font-weight: $fw-semibold;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    font-weight: normal;
    line-height: 1;
    color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
    margin-top: 3rem;
}


h1, .h1 {
    @include h1();
}

h2, .h2 {
    @include h2($color-primary);
}

h3, .h3 {
    @include h3($color-primary);
}

h4, .h4 {
    @include h4();
}

h5, .h5 {
    @include h5();
}

h6, .h6 {
    @include h6();
}

p,
ol,
ul,
dl,
address {
    margin: 0 0 var(--body-font-size);
}

small {
    font-size: var(--milli-font-size);
}

.heading-inline {
    display: inline;
    float: left;
    padding: 0;
}

.heading-w-subtitle {
    margin-bottom: 0;
}

.title-heading-center,
.title-heading-left{
    font-size:20pt;
    position:relative;
    display:inline-block;
    margin-left: auto;
    margin-right: auto;
    color:#1e1e1e;
    padding-bottom:5px;

    &:before {
        position:absolute;
        bottom:0;
        left:50%;
        width:60px;
        content:'';
        display:block;
        margin:0 auto 0;
        transform:translateX(-50%)
    }

    &:after {
        width:40px;
        content:'';
        display:block
    }
}

.title-heading-center {
    padding:0;
    text-align:center;
    display: block;
}

.title-heading-left:before{
    left:0;
    margin:0;
    transform:translateX(0)
}

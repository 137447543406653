
$link-transition:   250ms ease-in-out;

/* Fade in */
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Fade in down */
@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
    }
}
@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
    }
}

/* Fade in up */
@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(30px);
                transform: translateY(30px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(30px);
                transform: translateY(30px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

/* Fade in up margin */
@-webkit-keyframes fadeInUpMargin {
	from {
		opacity: 0;
		margin-bottom: -20px;
	}
	to {
		opacity: 1;
		margin-bottom: 0;
	}
}

@keyframes fadeInUpMargin {
	from {
		opacity: 0;
		margin-bottom: -20px;
	}
	to {
		opacity: 1;
		margin-bottom: 0;
	}
}


/* Grow in fade */
@-webkit-keyframes growInFade {
	from {
		opacity: 0;
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		        transform: none;
	}
}
@keyframes growInFade {
	from {
		opacity: 0;
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		        transform: none;
	}
}

/* ---- Highlight pulse ---- */
@-webkit-keyframes highlightPulse {
	40% {
		-webkit-transform: scale(1.25);
		        transform: scale(1.25);
	}
}
@keyframes highlightPulse {
	40% {
		-webkit-transform: scale(1.25);
		        transform: scale(1.25);
	}
}

/* ---- Highlight pulse with outline ---- */
@-webkit-keyframes highlightPulseOutline {
    0% {
        outline: 3px solid $color-utility-selection;
        outline-offset: 4px;
        -webkit-transform: scale(1);
		        transform: scale(1);
    }
	13% {
		-webkit-transform: scale(1.25);
		        transform: scale(1.25);
	}
    33% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    75% {
        outline: 3px solid $color-utility-selection;
        outline-offset: 4px;
    }
    100% {
        outline: 3px solid transparent;
        outline-offset: 4px;
    }
}
@keyframes highlightPulseOutline {
	0% {
        outline: 3px solid $color-utility-selection;
        outline-offset: 4px;
        -webkit-transform: scale(1);
		        transform: scale(1);
    }
	13% {
		-webkit-transform: scale(1.25);
		        transform: scale(1.25);
	}
    33% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    75% {
        outline: 3px solid $color-utility-selection;
        outline-offset: 4px;
    }
    100% {
        outline: 3px solid transparent;
        outline-offset: 4px;
    }
}

.animate-fade {
    animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.demo-animate:hover .demo-animate__item {
    clip: auto;
    height: auto;
    overflow: visible;
    position: relative;
    width: auto;
    padding: 5px;
    -webkit-animation: fadeInDown 400ms ease-in-out;
            animation: fadeInDown 400ms ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.table--content-small {
    font-size: 1.3rem;
}

.table--content-small th,
.table--content-small td {
    padding: 5px 10px;
}

.table--content-small th,
.table--content-small thead td {
    font-size: 1.4rem;
}
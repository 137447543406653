
.clear {
    height: 0;
    clear: both;
    display: block;
}

.-clearfix {
    @include clearfix;
}

@mixin box($size: 'regular') {
    padding: $spacing*2;

    > *:last-child {
        margin-bottom: 0;
    }

    @if $size == 'small' {
        @include screen-360 {
            padding: 24px;
        }
    }

    @if $size == 'regular' {
        @include screen-360 {
            padding: 24px;
        }

        @include screen-768 {
            padding: 30px;
        }
    }

    @if $size == 'large' {
        @include screen-360 {
            padding: 30px;
        }

        @include screen-768 {
            padding: 40px;
        }
    }
}

@mixin box-lined($colour: $color-grey-04, $accentBorder: 'none') {
    border: 2px solid $color-grey-03;
    border-radius: 4px;
    margin-bottom: $font-body;

    @if $accentBorder == 'left' {
        border-left: 5px solid $colour;
    }

    @if $accentBorder == 'bottom' {
        border-bottom: 5px solid $colour;
    }
}


a {
    background-color: transparent;
    color: $color-primary;
    text-decoration: none;
    transition:
        color $link-transition,
        opacity $link-transition;
    word-break: break-word;
    -webkit-text-decoration-skip: objects;

    &:after {
        transition-property:
            color,
            background-color,
            border-color;
        transition-duration: .2s;
        transition-timing-function: linear;
    }
}

a:hover,
a:active,
a:focus {
    color: darken($color-accent-01, 20%);
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
    outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
    outline: 0;
}

/* Include file type and size in document links */
a.document::after {
    content: " (" attr(data-ext) " " attr(data-size) ")";
}

:target {
    animation: highlightPulse 700ms ease;
    outline: 3px solid $color-utility-selection;
    outline-offset: 4px;
}

.active-target {
    animation: highlightPulseOutline 2100ms ease;
}

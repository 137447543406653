
html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
*, *:before, *:after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
}


/* -----------------------------
Visibility Utilities
------------------------------*/

@mixin vis-hidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &.focusable:active,
    &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

@mixin vis-hidden-reset() {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}


/* -----------------------------
Clearfix
------------------------------*/
@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}


/* -----------------------------
SVG
------------------------------*/
@mixin svg-contents() {
    path,
    circle,
    ellipse,
    rect,
    line,
    polygon {
        @content;
    }
}

/* -----------------------------
CSS Slider
------------------------------*/
@mixin css-slider {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
    }

    > * {
        flex: 0 0 100%;
        flex-shrink: 0;
        scroll-snap-align: start;
    }
}
